import axiosIns from "@/libs/axios";

export default class CategoryService {
    static get() {
        return axiosIns.get('/categories')
    }

    static store(data){
        return axiosIns.post('/categories', data)
    }

    static addImage(id, data){
        return axiosIns.post(`/categories/${id}/image`, data)
    }

    static update(id, data){
        return axiosIns.put(`/categories/${id}`, data)
    }

    static updateVerified(data){
        return axiosIns.post('/categories/verified-single', data)
    }
}
