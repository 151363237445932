<template>
  <Modal title="Icono" ref="icon_modal">
    <template v-slot:body>
      <form @submit.prevent="store">
        <div class="row">
          <div class="col-12">
            <input-drop-zone v-model="icon" folder="categories/icons" />
          </div>
          <div class="col-12 mt-2 text-right">
            <button-store :disabled="icon == null" />
          </div>
        </div>
      </form>
    </template>
  </Modal>
</template>

<script>
import InputDropZone from "@/components/InputDropZone";
import ButtonStore from "@/components/ButtonStore";
import CategoryService from "@/services/CategoryService";

export default {
  components: {ButtonStore, InputDropZone},
  data() {
    return {
      icon : null,
      id: null
    }
  },
  methods: {
    open(category) {
      this.id = category.id
      this.$refs.icon_modal.launch();
    },
    store() {
      CategoryService.update(this.id, { icon: this.icon }).then( (response) => {
        this.$refs.icon_modal.close()
        this.$showSuccess("Guardado correctamente")
      }).catch( () => {
        this.$showError("Error al guardar la imagen");
      });
    },
  }
}
</script>

<style scoped>

</style>
