<template>
  <Modal title="Crear Categoria" ref="modal_create">
    <template v-slot:body>
      <form @submit.prevent="store">
        <form-category v-model="form" />
      </form>
    </template>
  </Modal>
</template>

<script>
import CategoryService from "@/services/CategoryService";
import FormCategory from "@/views/catalogs/category/FormCategory";

export default {
  components: {
    FormCategory

  },
  data() {
    return {
      form: {
        profession_id: null,
        name: null,
        verified: true,
      }
    }
  },
  methods: {
    open() {
      this.form = { name: null, verified: true, profession_id: null}
      this.$refs.modal_create.launch();
    },
    store(){
      CategoryService.store(this.form).then(response => {
        this.$showSuccess("Se ha guardado correctamente");
        this.$refs.modal_create.close();
        this.$emit('onFinished');
      }).catch(error => {
        console.log(error)
      })
    }
  }
}
</script>

<style scoped>

</style>
